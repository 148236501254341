/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

1.  Global Styles
2.  Layout
3.  Special Templates
4.  Site Header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Subscribe Form
  7.2. Post Footer
  7.3. Comments
  7.4. Related Posts
  7.5. Floating Header
8.  Author Template
9.  Error Template
10. Subscribe Overlay
11. Site Footer

*/


/* 1. Global - Set up the things
/* ---------------------------------------------------------- */
@import "global.css";

@font-face {
    font-family: 'post_body_font';
    src: url('fonts/persans-webfont.woff2') format('woff2'),
         url('fonts/persans-webfont.woff') format('woff'),
         url('fonts/persans-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'post_title_font';
    src: url('fonts/librecaslontext-regular-webfont.woff2') format('woff2'),
         url('fonts/librecaslontext-regular-webfont.woff') format('woff'),
         url('fonts/librecaslontext-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

/* @font-face {
    font-family: 'test';
    src: url('fonts/greatvibes-regular-webfont.woff2') format('woff2'),
         url('fonts/greatvibes-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

} */

body {
    background: #ffffff;
}

.img {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
}

.hidden {
    visibility: hidden;
    position: absolute;
    text-indent: -9999px;
}


/* 2. Layout - Page building blocks
/* ---------------------------------------------------------- */

.site-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-main {
    z-index: 100;
    flex-grow: 1;
}

/* Full width page blocks */
.outer {
    position: relative;
    padding: 0 4vw;
}

.sports {
  background-color: var(--black);
}

/* Centered content container blocks */
.inner {
    margin: 0 auto;
    max-width: 1040px;
    width: 100%;
}

/* Usage:

<div class="outer">
    <div class="inner">
        Centered content
    </div>
</div>

*/

/* 3. Special Template Styles
/* ---------------------------------------------------------- */

@media (min-width: 900px) {
    .home-template .post-feed,
    .tag-template .post-feed,
    .sports-template .post-feed,
    .author-template .post-feed {
        margin-top: 0;
    }
    .home-template .site-nav,
    .sports-template .post-feed {
        position: relative;
        top: 0;
    }
}


/* 4. Site Header
/* ---------------------------------------------------------- */

.site-header {
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #000;
    /* background: color(var(--white)) no-repeat center center; */
    background-size: cover;
}

.site-header:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    /* background: rgba(255,255,255,1); */
}

.site-header:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    /* background: var(--white)*/ /* linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0)); */
}

.site-header.no-cover:before,
.site-header.no-cover:after {
    display: none;
}

.site-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7vw 4vw;
    min-height: 100px;
    max-height: 450px;
    text-align: center;
}

.site-title {
    z-index: 10;
    margin: 0;
    padding: 0;
    font-size: 3.8rem;
    color: var(--black);
    font-weight: 700;
    font-family: 'post_title_font'
;}

.site-logo {
    max-width: 300px;
}

.site-description {
    z-index: 10;
    margin: 0;
    padding: 5px 0;
    font-size: 2.2rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    opacity: 0.8;
}

@media (max-width: 500px) {
    .site-title {
        font-size: 3rem;
    }
    .site-description {
        font-size: 1.8rem;
    }
}

@media (max-width: 700px){
  .site-logo {
      max-width: 130px;
  }
}


/* 5. Site Navigation
/* ---------------------------------------------------------- */

.site-nav {
    position: relative;
    z-index: 300;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: hidden;
    height: 40px;
    font-size: 1.2rem;
}

.site-nav-left {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-right: 10px;
    padding: 10px;
    /* padding-bottom: 80px; */
    letter-spacing: 0.4px;
    white-space: nowrap;

    -ms-overflow-scrolling: touch;
}

/* Site Nav Hack Explanation (above):

What's happening above it .site-nav-left is set to overflow-x and allow sideways scrolling, so that when there isn't enough space for all nav items (either due to lots of nav items, or a small viewport), you can still scroll side-to-side to reach them.

The knock-on effect of this is ugly browser-scroll bars at the bottom, so 80px of padding-bottom and a 40px fixed height parent (.site-nav) hides that entirely. Slightly hacky code. But nice clean end-result.

*/

.site-nav-logo {
    flex-shrink: 0;
    display: block;
    margin-right: 24px;
    padding: 11px 0;
    color: #000;
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: bold;
    letter-spacing: -0.5px;
}

.site-nav-logo:hover {
    text-decoration: none;
}

.site-nav-logo img {
    display: block;
    width: auto;
    height: 21px;
}

.nav {
    display: flex;
    margin: 0 0 0 0px;
    padding: 0;
    list-style: none;
}

.nav li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.nav li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    color: #000;
    opacity: 0.8;
}

.nav li a:hover {
    text-decoration: none;
    opacity: 1;
}

.nav-dropdown {
    display: none;
    box-shadow: 1px 1px 3px 1px var(--lightgrey);
    border-radius: 10px;
}

.site-nav-right {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 40px;
}

.social-links {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.social-links a:last-of-type {
    padding-right: 20px;
}

.social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px;
    color: #000;
    opacity: 0.8;
}

.social-link:hover {
    opacity: 1;
}

.social-link svg {
    height: 1.8rem;
    fill: #000;
}

.social-link-fb svg {
    height: 1.5rem;
}

.social-link-wb svg {
    height: 1.6rem;
}

.social-link-wb svg path {
    stroke: #000;
}

.social-link-rss svg {
    height: 1.9rem;
}

.subscribe-button {
    display: block;
    padding: 4px 10px;
    border: #000 1px solid;
    color: #000;
    font-size: 1.2rem;
    line-height: 1em;
    border-radius: 10px;
    opacity: 0.8;
}

.subscribe-button:hover {
    text-decoration: none;
    opacity: 1;
}

.rss-button {
    opacity: 0.8;
}

.rss-button:hover {
    opacity: 1;
}

.rss-button svg {
    margin-bottom: 1px;
    height: 2.1rem;
    fill: #000;
}

@media (max-width: 500px) {
  .sports-template .post-feed,
  .home-template .post-feed, {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 40px;
  }
  .home-template .subscribe-button,
  .sports-template .subscribe-button {
    display:none;
  }
}

@media (max-width: 500px) {
    .site-header {
        padding-right: 0;
        padding-left: 0;
    }
    .site-nav-left {
        margin-right: 0;
        padding-left: 4vw;
    }
    .site-nav-right {
        display: none;
    }

}


/* 6. Post Feed
/* ---------------------------------------------------------- */

.post-feed {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0;
    padding: 0 0 0 0;
    float: left;
    width: 100%;
}

.post-feed-main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0;
    padding: 30px 0 0 0;
    float: left;
    width: 60%;
}

.post-feed-column {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0;
    padding: 0 0 0 0;
    float: left;
    width: 40%;
    border-left: 1px solid var(--lightgrey);
}

.title-column {
  /* text-decoration: underline; */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  float: left;
  padding-left: 30px;
  padding-top: -200px;
  width: 40%;
  color:#15171A;
  font-family: 'post_title_font';
}

/* raise1 raise2 secondary and tertiary are hacky ways to get the main page in the right format for browsers */

.title {
  /* text-decoration: underline; */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  float: left;
  padding-left: 30px;
  padding-top: -200px;
  width: 100%;
  font-family: 'post_title_font';
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caption {
  width: 80%;
  font-size:80%;
  text-align:center;
  padding-bottom:3em;
  align: left;
}

.caption img {
  width: 100%
}

.post-card {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    min-height: 100px;
    background: #fff center center;
    background-size: cover;
    border-radius: 5px;
    box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    transition: all 0.5s ease;
    font-family: 'post_title_font';
}

.post-card-column {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 5px;
    min-height: 0px;
    background: #fff center center;
    background-size: cover;
    border-radius: 0px;
    /* border-style: solid;
    border-width: 1px;
    border-color: var(--darkgrey); */
    transition: all 0.5s ease;
    font-family: 'post_title_font';
}

.see-more {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* align-items: flex-end; */
  margin: 0 20px 5px;
}

.post-card-noshadowhover {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    min-height: 100px;
    background: #fff center center;
    background-size: cover;
    border-radius: 5px;
    /* box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px; */
    transition: all 0.5s ease;
    font-family: 'post_title_font';
}

.nohover {
  pointer-events: none;
}

.post-card:hover {
    box-shadow: 0 0 1px rgba(39,44,49,0.10), 0 3px 16px rgba(39, 44, 49,0.07);
    transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0);
}

.post-card-column:hover {
    box-shadow: 0 0 1px rgba(39,44,49,0.05), 0 3px 16px rgba(39, 44, 49,0.03);
    transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0);
}

.post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 0px 0px 0 0;
}

.post-card-image {
    width: auto;
    height: 200px;
    background: var(--lightgrey) no-repeat center center;
    background-size: cover;
}

.post-card-image-main {
    width: auto;
    height: 400px;
    background: var(--lightgrey) no-repeat top center;
    background-size: cover;
}

.post-card-content-link {
    position: relative;
    display: block;
    padding: 2vw 2vw 0;
    color: var(--darkgrey);
}

.post-card-content-link:hover {
    text-decoration: none;
}

.post-card-tags {
    display: block;
    margin-bottom: 4px;
    color: var(--midgrey);
    font-size: 1.2rem;
    line-height: 1.15em;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.post-card-title {
    margin-top: 0;
}

.post-card-title-column {
    font-size: 1.5rem;
    margin-top: 0;
}

.post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.post-card-excerpt {
    font-family: 'post_body_font';
}

.post-card-meta {
    font-size: 1rem;
    padding: 0 2vw 2vw;
}

.author-profile-image {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;

    object-fit: cover;
}

.post-card-author {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

/* Special Styling for home page grid (below):

The first (most recent) post in the list is styled to be bigger than the others and take over the full width of the grid to give it more emphasis. Wrapped in a media query to make sure this only happens on large viewports / desktop-ish devices.

 */

/* @media (min-width: 795px) {
    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) {
        flex: 1 1 100%;
        flex-direction: row;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-image-link {
        position: relative;
        flex: 1 1 auto;
        border-radius: 5px 0 0 5px;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-content {
        flex: 0 1 357px;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) h2 {
        font-size: 2.6rem;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) p {
        font-size: 1.8rem;
        line-height: 1.55em;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-content-link {
        padding: 30px 40px 0;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-meta {
        padding: 0 40px 30px;
    }
}

.home-template .site-header:after {
    display: none;
} */


/* Adjust some margins for smaller screens */

@media (min-width: 1140px) {
  .raise1 {
    top: -52px;
  }

  .raise2 {
    top: -104px;
  }
  .secondary {
    padding-left: 375px;
    top: -52px;
  }

  .tertiary {
    padding-left: 715px;
    top: -104px;
  }

}

@media (max-width: 650px) {
    .post-feed {
        padding-top: 0;
        padding-bottom: 40px;
        width: 100%;
    }
    .post-card {
        margin: 0 20px 5vw;
    }
    .title {
      padding-left: 30px;
    }

    .raise1 {
      top: -52px;
    }

    .raise2 {
      top: -104px;
    }
    .secondary {
      position: relative;
      padding-left: 33vw;
      top: -52px;
    }

    .tertiary {
      position: relative;
      padding-left: 63vw;
      top: -104px;
    }

    .post-feed-main {
      width: 70%;
    }

    .post-feed-column {
      width: 30%;
    }

    .title-column {
      width: 30%;
    }

    .post-card-content-link {
        padding: 3vw 3vw 0;
    }
}

@media (max-width: 500px) {

    .post-feed-main {
      width: 100%;
    }

    .post-feed-column {
      width: 100%;
    }

    .title-column {
      width: 100%;
    }


}

/* 7. Single Post
/* ---------------------------------------------------------- */

.post-template .site-main,
.page-template .site-main {
    padding-bottom: 4vw;
    /* background: var(--white); */
}

.post-full {
    position: relative;
    z-index: 50;
}
/* ^ Required to make .post-full-content:before/after z-index stacking work */

.post-full-header {
    margin: 0 auto;
    padding: 6vw 3vw 3vw;
    max-width: 1040px;
    font-family: 'post_title_font';
    text-align: center;
}
@media (max-width: 500px) {
    .post-full-header {
        padding: 14vw 3vw 10vw;
    }
}

.post-full-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--midgrey);
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
}

.post-full-meta-date {
    color: var(--blue);
}

.post-full-title {
    margin: 0;
    color: color(var(--darkgrey) l(-5%));
}

.date-divider {
    display: inline-block;
    margin: 0 6px 1px;
}

.post-full-image {
    margin: 0 -4vw 0px;
    height: 600px;
    background: var(--lightgrey) center center;
    background-size: cover;
    border-radius: 5px;
}

.post-footnote {
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: 'post_body_font';

}

@media (max-width: 1170px) {
    .post-full-image {
        border-radius: 0;
        border-color: var(--white);
        border-style: solid;
        border-width: 0vw 4.2vw 4vw 4.2vw;
    }
}

@media (max-width: 800px) {
    .post-full-image {
        height: 500px;
    }
}

.post-full-content {
    position: relative;
    margin: 0;
    padding: 2vw 7vw 0;
    min-height: 230px;
    font-family: 'post_body_font';
    font-size: 2.0rem;
    line-height: 1.6em;
    background: #fff;
}


@media (max-width: 800px) {
    .post-full-content {
        font-size: 1.7rem;
    }
}

/* .post-full-content:before {
    content: "";
    position: absolute;
    top: 15px;
    left: -5px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39,44,49,0.15);
    filter: blur(5px);
    transform: rotate(-5deg);
}

.post-full-content:after {
    content: "";
    position: absolute;
    top: 15px;
    right: -5px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39,44,49,0.15);
    filter: blur(5px);
    transform: rotate(5deg);
} */

.no-image .post-full-content {
    padding-top: 0;
}

.no-image .post-full-content:before,
.no-image .post-full-content:after {
    display: none;
}

.kg-card-markdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 920px;
}

/* Sourced from https://css-tricks.com/snippets/css/drop-caps/ */
.first-letter > p:first-of-type::first-letter {
  float: left;
  font-family: Georgia;
  font-size: 75px;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6,
.post-full-content p,
.post-full-content ul,
.post-full-content ol,
.post-full-content dl,
.post-full-content pre,
.post-full-content blockquote,
.post-full-comments,
.footnotes {
    min-width: 100%;
}

.post-full-content li {
    word-break: break-word;
}

.post-full-content li p {
    margin: 0;
}

/* .post-template .kg-card-markdown > p:first-child {
    font-size: 1.25em;
    line-height: 1.5em;
} */

.post-full-content a {
    color: #000;
    box-shadow: var(--blue) 0 -1px 0 inset;
}

.post-full-content a:hover {
    color: var(--blue);
    text-decoration: none;
}

.post-full-content strong,
.post-full-content em {
    color: color(var(--darkgrey) l(-5%));
}

.post-full-content small {
    display: inline-block;
    line-height: 1.6em;
}

.post-full-content li:first-child {
    margin-top: 0;
}

.post-full-content img,
.post-full-content video {
    display: block;
    margin: 0.7em auto;
    max-width: 1040px;
}
@media (max-width: 1040px) {
    .post-full-content img,
    .post-full-content video {
        width: 100%;
    }
}


/* Full bleed images (#full)
Super neat trick courtesy of @JoelDrapper

Usage (In Ghost edtior):

![img](/some/image.jpg#full)

*/
.post-full-content img[src$="#full"] {
    max-width: none;
    width: 100vw;
}


/* Image captions

Usage (In Ghost editor):

![img](/some/image.jpg)
<small>Your image caption</small>

*/
.post-full-content img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
}


.post-full-content iframe {
    margin: 0 auto;
}

.post-full-content blockquote {
    margin: 0 0 1.5em;
    padding: 0 1.5em;
    border-left: var(--green) 3px solid; /* #3eb0ef */
}

.post-full-content blockquote p {
    margin: 0 0 1em 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: italic;
}

.post-full-content blockquote p:last-child {
    margin-bottom: 0;
}

.post-full-content code {
    padding: 0 5px 2px;
    font-size: 0.8em;
    line-height: 1em;
    font-weight: 400!important;
    background: var(--whitegrey);
    border-radius: 3px;
}

.post-full-content pre {
    overflow-x: auto;
    margin: 1.5em 0 3em;
    padding: 20px;
    max-width: 100%;
    border: color(var(--darkgrey) l(-10%)) 1px solid;
    color: var(--whitegrey);
    font-size: 1.4rem;
    line-height: 1.5em;
    background: color(var(--darkgrey) l(-3%));
    border-radius: 5px;
}

.post-full-content pre code {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    background: transparent;
}

.post-full-content pre code * {
    color: inherit;
}

.post-full-content .fluid-width-video-wrapper {
    margin: 1.5em 0 3em;
}

.post-full-content hr {
    margin: 4vw 0;
}

.post-full-content hr:after {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    display: block;
    margin-left: -10px;
    width: 1px;
    height: 30px;
    background: color(var(--lightgrey) l(+10%));
    box-shadow: #fff 0 0 0 5px;
    transform: rotate(45deg);
}

.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6 {
    color: color(var(--darkgrey) l(-5%));
    font-family: 'post_body_font';
}

.post-full-content h1 {
    margin: 0.5em 0 0.2em 0;
    font-size: 4.6rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .post-full-content h1 {
        font-size: 2.8rem;
    }
}

.post-full-content h2 {
    margin: 0.5em 0 0.2em 0;
    font-size: 3.6rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .post-full-content h2 {
        font-size: 2.6rem;
    }
}

.post-full-content h3 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.8rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .post-full-content h3 {
        font-size: 2.2rem;
    }
}

.post-full-content h4 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.3rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .post-full-content h4 {
        font-size: 2.0rem;
    }
}

.post-full-content h5 {
    display: block;
    margin: 0.5em 0;
    padding: 1em 0 1.5em;
    border: 0;
    color: var(--green);
    font-family: 'post_body_font';
    font-size: 3.2rem;
    line-height: 1.35em;
    text-align: center;
}
@media (min-width: 1180px) {
    .post-full-content h5 {
        max-width: 1060px;
        width: 100vw;
    }
}
@media (max-width: 500px) {
    .post-full-content h5 {
        padding: 0 0 0.5em;
        font-size: 2.2rem;
    }
}

.post-full-content h6 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.3rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .post-full-content h6 {
        font-size: 2rem;
    }
}

.footnotes-sep {
    margin-bottom: 30px;
}

.footnotes {
    font-size: 1.5rem;
}

.footnotes p {
    margin: 0;
}

.footnote-backref {
    color: var(--green) !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: none !important;
}

/* Some grouped styles for smaller viewports */
@media (max-width: 500px) {
    .post-full-meta {
        font-size: 1.2rem;
        line-height: 1.3em;
    }
    .post-full-title {
        font-size: 2.9rem;
    }
    .post-full-image {
        margin-bottom: 4vw;
        height: 350px;
    }
    .post-full-content {
        padding: 0;
    }
    .post-full-content:before,
    .post-full-content:after {
        display: none;
    }
}

/* Tables */
.post-full-content table {
    display: block;
    overflow-x: auto;
    margin: 2.5em 0 2.5em;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: 'post_body_font';
    font-size: 1.6rem;
    /* white-space: nowrap; */
    vertical-align: top;
}

.post-full-content table {
    -webkit-overflow-scrolling: touch;
    background-color: transparent;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
}

.post-full-content table td:first-child {
    /* background-image: linear-gradient(to right, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat; */
}

.post-full-content table td:last-child {
    /* background-image: linear-gradient(to left, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat; */
}

.post-full-content table th {
    color: var(--darkgrey);
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: color(var(--whitegrey) l(+4%));
}

.post-full-content table th,
.post-full-content table td {
    padding: 6px 12px;
    border: color(var(--midgrey) l(-1%) s(-5%)) 1px solid;
}


/* 7.1. Subscribe Form
/* ---------------------------------------------------------- */

.subscribe-form {
    margin: 1.5em 0;
    padding: 6.5vw 7vw 7vw;
    border: color(var(--whitegrey) l(+2%)) 1px solid;
    text-align: center;
    background: rgba(152, 194, 46, 0.2);
    border-radius: 7px;
}

.subscribe-form-title {
    margin: 0 0 3px 0;
    padding: 0;
    color: var(--darkgrey);
    font-size: 3.5rem;
    line-height: 1;
    font-weight: 700;
}

.subscribe-form p {
    margin-bottom: 1em;
    color: var(--midgrey);
    font-size: 2.2rem;
    line-height: 1.55em;
    letter-spacing: 0.2px;
}

.subscribe-form form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 420px;
}

.subscribe-form .form-group {
    flex-grow: 1;
}

.subscribe-email {
    display: block;
    padding: 10px;
    width: 100%;
    border: color(var(--lightgrey) l(+7%)) 1px solid;
    color: var(--midgrey);
    font-size: 1.8rem;
    line-height: 1em;
    font-weight: normal;
    user-select: text;
    border-radius: 5px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.subscribe-email:focus {
    outline: 0;
    border-color: color(var(--lightgrey) l(-2%));
}

.subscribe-form button {
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 0 20px;
    height: 41px;
    outline: none;
    color: #fff;
    font-size: 1.5rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
    background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 5px;
    box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}

.subscribe-form button:active,
.subscribe-form button:focus {
    background: color(var(--blue) lightness(-9%) saturation(-10%));
}

@media (max-width: 650px) {
    .subscribe-form-title {
        font-size: 2.4rem;
    }
    .subscribe-form p {
        font-size: 1.6rem;
    }
}

@media (max-width: 500px) {
    .subscribe-form form {
        flex-direction: column;
    }
    .subscribe-form .form-group {
        width: 100%;
    }
    .subscribe-form button {
        margin: 10px 0 0 0;
        width: 100%;
    }
}


/* 7.2. Post Footer
/* ---------------------------------------------------------- */

.post-full-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 3vw 0 6vw 0;
    max-width: 840px;
    font-family: 'post_body_font';
}

.author-card {
    display: flex;
    width: 100%;
    align-items: center;
}

.author-card .author-profile-image {
    margin-right: 15px;
    width: 60px;
    height: 60px;
}

.author-card-name {
    margin: 0 0 2px 0;
    padding: 0;
    font-size: 2rem;
}

.author-card-name a {
    color: var(--darkgrey);
    font-weight: 700;
}

.author-card-name a:hover {
    text-decoration: none;
}

.author-card-content p {
    margin: 0;
    color: var(--midgrey);
    line-height: 1.3em;
}

.post-full-footer-right {
    flex-shrink: 0;
    padding-right: 20px;
    margin-left: 20px;
}

.author-card-button {
    display: block;
    padding: 9px 16px;
    border: color(var(--midgrey) l(+20%)) 1px solid;
    color: var(--midgrey);
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 500;
    border-radius: 20px;
    transition: all ease 0.2s;
}

.author-card-button:hover {
    border-color: var(--green);
    color: var(--green);
    text-decoration: none;
}


/* 7.3. Comments
/* ---------------------------------------------------------- */

.post-full-comments {
    margin: 0 auto;
    max-width: 840px;
}


/* 7.4. Related posts
/* ---------------------------------------------------------- */

.read-next-feed {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 40px 0 0 0;
}

.read-next-card {
    position: relative;
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    padding: 25px;
    color: #fff;
    background: var(--darkgrey) center center;
    background-size: cover;
    border-radius: 5px;
    box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    font-family: 'post_title_font';
}

.read-next-card:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(135deg, rgba(0,40,60,0.8) 0%,rgba(0,20,40,0.7) 100%);
    border-radius: 5px;

    backdrop-filter: blur(2px);
}

.read-next-card-header {
    position: relative;
    z-index: 50;
    padding-top: 20px;
    text-align: center;
}

.read-next-card-header-sitetitle {
    display: block;
    font-size: 1.3rem;
    line-height: 1.3em;
    opacity: 0.8;
}

.read-next-card-header-title {
    margin: 0;
    padding: 0 20px;
    color: #fff;
    font-size: 3rem;
    line-height: 1.2em;
    letter-spacing: 1px;
}

.read-next-card-header-title a {
    color: #fff;
    font-weight: 300;
    text-decoration: none;
}

.read-next-card-header-title a:hover {
    text-decoration: none;
}

.read-next-divider {
    position: relative;
    display: flex;
    justify-content: center;
    height: 80px;
}

.read-next-divider svg {
    width: 40px;
    fill: transparent;
    stroke: #fff;

    stroke-width: 0.5px;
    stroke-opacity: 0.65;
}

.read-next-card-content {
    position: relative;
    z-index: 50;
    flex-grow: 1;
    display: flex;
    font-size: 1.7rem;
}

.read-next-card-content ul {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style: none;
}

.read-next-card-content li {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.25em;
    font-weight: 200;
    letter-spacing: -0.5px;
}

.read-next-card-content li a {
    display: block;
    padding: 20px 0;
    border-bottom: rgba(255,255,255,0.3) 1px solid;
    color: #fff;
    font-weight: 500;
    vertical-align: top;
    transition: opacity 0.3s ease;
}

.read-next-card-content li:first-of-type a {
    padding-top: 10px;
}

.read-next-card-content li a:hover {
    opacity: 1;
}

.read-next-card-footer {
    position: relative;
    margin: 15px 0 3px 0;
    text-align: center;
}

.read-next-card-footer a {
    color: #fff;
}


/* 7.5. Floating Header
/* ---------------------------------------------------------- */

.floating-header {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: rgba(0,0,0,0.06) 1px solid;
    background: rgba(255,255,255,0.95);
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate3d(0, -120%, 0);
    font-family: 'post_title_font';
}

.floating-active {
    visibility: visible;
    transition: all 500ms cubic-bezier(0.22, 1, 0.27, 1);
    transform: translate3d(0, 0, 0);
}

.floating-header-logo {
    overflow: hidden;
    margin: 0 0 0 20px;
    font-size: 1.6rem;
    line-height: 1em;
    letter-spacing: -1px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.floating-header-logo a {
    display: flex;
    align-items: center;
    color: var(--darkgrey);
    line-height: 1.1em;
    font-weight: 700;
}

.floating-header-logo a:hover {
    text-decoration: none;
}

.floating-header-logo img {
    margin: 0 10px 0 0;
    max-height: 20px;
}

.floating-header-divider {
    margin: 0 5px;
    line-height: 1em;
}

.floating-header-title {
    flex: 1;
    overflow: hidden;
    margin: 0;
    color: #2e2e2e;
    font-size: 1.6rem;
    line-height: 1.3em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.floating-header-share {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2%;
    font-size: 1.3rem;
    line-height: 1;
}

.floating-header-share a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.floating-header-share svg {
    width: auto;
    height: 16px;
    fill: #fff;
}

.floating-header-share-label {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: rgba(0,0,0,0.7);
    font-weight: 500;
}

.floating-header-share-label svg {
    margin: 0 5px 0 10px;
    width: 18px;
    height: 18px;
    stroke: rgba(0,0,0,0.7);
    transform: rotate(90deg);
}

.floating-header-share-tw,
.floating-header-share-fb {
    display: block;
    align-items: center;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background: transparent;

}

/* .floating-header-share-tw {
    background: #33b1ff;
}

.floating-header-share-fb {
    background: #005e99;
} */

.progress {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    color: var(--green);
    background: transparent;

    appearance: none;
}

.progress::-webkit-progress-bar {
    background-color: transparent;
}

.progress::-webkit-progress-value {
    background-color: var(--green);
}

.progress::-moz-progress-bar {
    background-color: var(--green);
}

.progress-container {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: transparent;
}

.progress-bar {
    display: block;
    width: 50%;
    height: inherit;
    background-color: var(--green);
}

@media (max-width: 900px) {
    .floating-header {
        height: 40px;
    }
    .floating-header-title,
    .floating-header-logo {
        font-size: 1.5rem;
    }
    .floating-header-share-tw,
    .floating-header-share-fb {
        width: 40px;
        height: 40px;
        line-height: 38px;
    }
}

@media (max-width: 800px) {
    .floating-header-logo {
        margin-left: 10px;
    }
    .floating-header-logo a {
        color: #2e2e2e;
    }
    .floating-header-title,
    .floating-header-divider {
        visibility: hidden;
    }
}

@media (max-width: 450px) {
    .floating-header-share-label {
        display: none;
    }
}


/* 8. Author Template
/* ---------------------------------------------------------- */

.site-header-content .author-profile-image {
    z-index: 10;
    flex-shrink: 0;
    margin: 0 0 20px 0;
    width: 100px;
    height: 100px;
    box-shadow: rgba(255,255,255,0.1) 0 0 0 6px;
}

.site-header-content .author-bio {
    z-index: 10;
    flex-shrink: 0;
    margin: 5px 0 10px 0;
    max-width: 600px;
    font-size: 2rem;
    line-height: 1.3em;
    font-weight: 300;
    letter-spacing: 0.5px;
    opacity: 0.8;
    font-family: 'post_body_font';
}

.site-header-content .author-meta {
    z-index: 10;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
    font-family: 'post_body_font';
    font-style: italic;
    font-size: 1em;
}

.site-header-content .author-location svg {
    height: 1.9rem;
    stroke: #fff;
}

.site-header-content .bull {
    display: inline-block;
    margin: 0 12px;
    opacity: 0.5;
}

.site-header-content .social-link:first-of-type {
    padding-left: 4px;
}

@media (max-width: 500px) {
    .site-header-content .author-bio {
        font-size: 1.8rem;
        line-height: 1.15em;
        letter-spacing: 0;
    }

    .site-header-content .author-meta {
        font-size: 0.7em;
    }
}

@media (max-width: 350px) {
    .author-location,
    .author-stats {
        display: none;
    }
}


/* 9. Error Template
/* ---------------------------------------------------------- */

.error-template .site-main {
    padding: 7vw 4vw;
}

.site-nav-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.site-nav-center .site-nav-logo {
    margin-right: 0;
}

.error-message {
    text-align: center;
}

.error-code {
    margin: 0;
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
    opacity: 0.3;
}

.error-description {
    margin: 0;
    color: var(--midgrey);
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 400;
}

@media (max-width: 800px) {
    .error-description {
        margin: 5px 0 0 0;
        font-size: 1.8rem;
    }
}

.error-link {
    display: inline-block;
    margin-top: 5px;
}

.error-template .post-feed {
    padding-top: 0;
}


/* 10. Subscribe Overlay
/* ---------------------------------------------------------- */

.subscribe-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0,100,50,0.95);
    /* opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none; */

    backdrop-filter: blur(3px);
}

/* .subscribe-overlay:target {
    opacity: 1;
    pointer-events: auto;
} */

.subscribe-overlay-content {
    position: relative;
    z-index: 9000;
    margin: 6vw 0 5vw 0;
    padding: 4vw;
    color: #fff;
    text-align: center;
}

.subscribe-overlay-logo {
    position: fixed;
    top: 23px;
    left: 30px;
    height: 30px;
}

.subscribe-overlay-title {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 4rem;
    line-height: 1.15em;
}

.subscribe-overlay-description {
    margin: 0 auto 50px;
    max-width: 650px;
    font-family: 'post_body_font';
    font-size: 2rem;
    line-height: 1.3em;
    font-weight: 300;
    opacity: 0.8;
}

.subscribe-overlay form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
}

.subscribe-overlay .form-group {
    flex-grow: 1;
}

.subscribe-overlay .subscribe-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: var(--midgrey);
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    user-select: text;
    border-radius: 8px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.subscribe-email:focus {
    outline: 0;
    border-color: color(var(--lightgrey) l(-2%));
}

.subscribe-overlay button {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0 25px;
    height: 52px;
    outline: none;
    color: #fff;
    font-size: 1.7rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
    background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 8px;
    box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}

.subscribe-overlay button:active,
.subscribe-overlay button:focus {
    background: color(var(--blue) lightness(-9%) saturation(-10%));
}

.subscribe-overlay-close {
    position: absolute;
    top: 6vh;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
}

.subscribe-overlay-close:before {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(45deg);
}

.subscribe-overlay-close:after {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(-45deg);
}

.subscribe-overlay-close:hover {
    cursor: default;
}

@media (max-width: 650px) {
  .subscribe-overlay-title {
      font-size: 3rem;
  }

  .subscribe-overlay-description {
      font-size: 1.5rem;
  }
  .subscribe-overlay .subscribe-email {
      font-size: 1.3rem;
  }
  .subscribe-overlay button {
      font-size: 1.3rem;
  }
}


/* 10.5 Privacy Policy and T&C overvaly box */

.agreement-overlay {
    position: fixed;
    top: 70vh;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--midgrey);
    opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none;

    backdrop-filter: blur(3px);
}

.slideup, .slidedown {
    max-height: 0;
    overflow-y: hidden;
    -webkit-transition: max-height 0.8s ease-in-out;
    -moz-transition: max-height 0.8s ease-in-out;
    -o-transition: max-height 0.8s ease-in-out;
    transition: max-height 0.8s ease-in-out;
}
.slidedown {
    max-height: 70vh;
}

.agreement-overlay:target {
    opacity: 1;
    pointer-events: auto;
}

.agreement-overlay-content {
    position: relative;
    z-index: 9999;
    margin: 0 0 5vw 0;
    padding: 4vw;
    color: #fff;
    text-align: center;
}

.agreement-overlay-logo {
    position: fixed;
    top: 23px;
    left: 30px;
    height: 30px;
}

.agreement-overlay-title {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 4rem;
    line-height: 1.15em;
}

.agreement-overlay-description {
    margin: 0 auto 50px;
    max-width: 650px;
    font-family: 'post_body_font';
    font-size: 2rem;
    line-height: 1.3em;
    font-weight: 300;
    opacity: 0.8;
}

.agreement-overlay form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
}

.agreement-overlay .form-group {
    flex-grow: 1;
}

.agreement-overlay .agreement-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: var(--midgrey);
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    user-select: text;
    border-radius: 8px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.agreement-email:focus {
    outline: 0;
    border-color: color(var(--lightgrey) l(-2%));
}

.agreement-overlay button {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0 25px;
    height: 52px;
    outline: none;
    color: #fff;
    font-size: 1.7rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
    background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 8px;
    box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}

.agreement-overlay button:active,
.agreement-overlay button:focus {
    background: color(var(--blue) lightness(-9%) saturation(-10%));
}

.agreement-overlay-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
}

.agreement-overlay-close:before {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(45deg);
}

.agreement-overlay-close:after {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(-45deg);
}

.agreement-overlay-close:hover {
    cursor: default;
}

/* 11. Site Footer
/* ---------------------------------------------------------- */

.site-footer {
    position: relative;
    padding-top: 20px;
    padding-bottom: 60px;
    color: #fff;
    background: color(var(--darkgrey) l(-15%));
}

.site-footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: rgba(255,255,255,0.7);
    font-size: 1.3rem;
}

.site-footer-content a {
    color: rgba(255,255,255,0.7);
}

.site-footer-content a:hover {
    color: rgba(255,255,255,1);
    text-decoration: none;
}

.site-footer-nav {
    display: flex;
}

.site-footer-nav a {
    position: relative;
    margin-left: 20px;
}

.site-footer-nav a:before {
    content: "";
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
}

.site-footer-nav a:first-of-type:before {
    display: none;
}

@media (max-width: 650px) {
    .site-footer-content {
        flex-direction: column;
    }
    .site-footer-nav a:first-child {
        margin-left: 0;
    }
}

/* 12. Cookie Notice
/* ---------------------------------------------------------- */

#cookie-notice {
    padding: 0.5rem 1rem; 
    display: none; 
    text-align: center; 
    position: fixed; 
    bottom: 0; 
    z-index: 9001; 
    width: calc(100% - 4rem); 
    margin-left: 2rem; 
    margin-bottom: 2rem; 
    background: #222; 
    color: rgba(255,255,255,0.8);
}
#cookie-notice a {
    display: inline-block; 
    cursor: pointer; 
    margin-left: 0.5rem;
}

@media (max-width: 767px) {
    #cookie-notice span {
        display: block; 
        padding-top: 3px; 
        margin-bottom: 1rem;
    }
    #cookie-notice a {
        position: relative; 
        bottom: 4px;
    }
}